.dialog-open {
  .p-dialog-mask {
    background-color: rgba($gray-dark, 0.1);
    backdrop-filter: blur(2px);
  }

  .p-dialog {
    min-width: 40vw;
    max-width: 50vw;

    &.overflow-visible .p-dialog-content {
      overflow-y: visible;
    }

    &.dialog-md {
      min-width: 600px;
    }

    .p-dialog-header {
      background: var(--cap-primary-color);
      color: $white;
      justify-content: flex-start;

      .p-dialog-title {
        flex: 1;
        font-size: 22px;
        font-weight: 600;
        padding: 1.31rem 0;
        margin-left: 44px;
      }

      .p-dialog-header-icons {

        .p-dialog-header-icon {
          color: $white;
        }
      }
    }

    timesicon.p-element.p-icon-wrapper:after {
      content: '\f00d';
      font-family: 'Font Awesome 5 Pro';
      font-size: 21px;
      margin-right: 44px;
    }

    timesicon svg {
      display: none;
    }

    .p-dialog-content {
      padding: 0px 48px 26px;
      font-size: 1rem;
    }

    .dialog-layout--content {
      border-bottom: 1px solid $border-color;
      color: $gray-dark;
      padding: 35px 0 12px;

      .cpb-grid {
        padding: 0;
      }
    }

    .dialog-layout--footer {
      padding-top: 30px;

      .buttons {
        display: flex;
        justify-content: space-between;
      }
    }
  }

  .p-confirm-dialog {
    .p-dialog-content {
      margin: 12px 150px 25px;
      justify-content: center;
    }

    .p-dialog-footer {
      border-top: 1px solid $border-color;
      margin: 0 50px;
      padding: 35px 0;
      font-size: 1rem;

      button {
        margin-right: 0;
      }
    }
  }
}
