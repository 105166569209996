cpb-form-renderer {
  cap-calendar .p-inputgroup input.p-inputtext[readonly],
  cap-calendar .p-inputgroup input.p-inputtext[readonly]:enabled:hover:not(.p-state-error),
  cap-calendar .p-inputgroup input.p-inputtext[readonly]:enabled:hover {
    border: 1px solid $border-color;
  }

  .checkbox-wrapper.form-group.position-before .checkbox-wrapper-container {
    flex-direction: row-reverse;

    cpb-checkbox-input > div {
      justify-content: end;
    }

    .label-wrapper {
      flex: 0 1 51%;
      max-width: 51%;
      margin-left: 10px;

      label {
        font-weight: normal;
      }
    }
  }

  .cbp-default-input-wrapper {
    .cpb-required-indicator {
      color: var(--cap-primary-color);
      margin-left: 3px;
      padding-right: 5px;
    }

    .readonly-container {
      min-height: 30px;
    }

    &.cpb-readonly.form-group.position-before {
      margin: 10px 0;
      flex-direction: column;
      @include media-breakpoint-up(sm) {
        flex-direction: row;
      }
      @include media-breakpoint-up(lg) {
        flex-direction: column;
      }
      @include media-breakpoint-up(xxl) {
        flex-direction: row;
      }
    }

    &.form-group.position-before {
      display: flex;
      align-items: baseline;
      margin: 0;
      margin-right:21px;
      margin-left: 21px;

      @include media-breakpoint-up(sm) {
        flex-direction: row;
        margin: 0;
        margin-right: 21px;
        margin-left: 21px;
      }

      .field-wrapper {
        padding-right: 0;
      }

      .label-wrapper {
        flex: 0 1 100%;
        max-width: 100%;
        margin-bottom: 1.2rem;
        @include media-breakpoint-up(sm) {
          flex: 0 1 40%;
          max-width: 40%;
          margin-bottom: 1.2rem;
        }
      }
    }

    .form-invalid-feedback {
      min-height: unset;
    }
  }

  cpb-label-input {
    label {
      margin: 0;
    }
  }

  .border-columns > .border-column {
    padding: 0 30px;
  }

  .border-columns > .border-column:not(:last-child) {
    margin-bottom: 30px;
    @include media-breakpoint-up(lg) {
      border-right: 1px solid $border-color;
      margin-bottom: 0;
    }
  }

  .form-linked-to div.readonly-container {
    color: var(--cap-primary-color);
    flex-direction: row;
    font-weight: 600;
    padding-top: 5px;
    justify-content: flex-start;

    &:not(:empty)::before {
      margin-right: 5px;
      font-family: 'Font Awesome 6 Pro';
      content: '\f0c1';
      font-size: 0.9rem;
    }
  }
}
