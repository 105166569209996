/* General colors */
$primary: #0397cf;
$primary-light: #ffffff;
$liver: #4f5152;
$blue: #0286b8;

$muted-text-color: #a0a0a0;

/* Fonts */
$font-light: opensans light;
$font-regular: opensans regular;
$font-semi-bold: opensans semibold;
$font-bold: opensans bold;

/* Spacing */
$mobile-spacing: 15px;
$dashboard-page-padding: 40px;

/* General */
$body-color: #5f6062;
$white: #fff;
$border-color: #ddd;

$cap-input-height: 33px;
$cap-button-success-background: #41ab00;
$placeholder-color: #b5b5b5;

/* General sizes */
$nav-height: 72px;

/* General */
$gray-light: #f9f9f9;
$gray-dark: #3a3a3a;
$gray-dark-secondary: #7a7a7a;
$body-color: #5f6062;
$dark-lava: #333;
$white: #fff;
$black: #000;
$platinum: #e6e6e6;
$cap-success: #41ab00;
$cap-danger: #e30007;


$button-gradient: linear-gradient(-225deg, rgb(3, 151, 207) 0%, rgb(0, 141, 194) 100%);

$cap-dynamic-filters-filter-height: 35px;
$cap-input-height: 33px;

$menu-height: 90px;
$mobile-menu-height: 65px;
